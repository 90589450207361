import React from 'react';
import { FlagProvider, useFlagsStatus } from '@unleash/proxy-client-react';
import FabAIChatWindow from './window/fabAIChatLanding';
import useAppContainer from './window/hooks/useAppContainer';
import { useAppRedirection } from './window/hooks/useAppRedirection';

const unleashConfig = {
  url: process.env.UNLEASH_PROXY_URL ?? '',
  clientKey: process.env.UNLEASH_CLIENT_KEY ?? '',
  appName: 'copilot-admin',
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem('accountId') ?? '',
  },
};

const App = () => {
  return (
    <FlagProvider config={unleashConfig}>
      <FlagMiddleware>
        <AppContainer />
      </FlagMiddleware>
    </FlagProvider>
  );
};

const FlagMiddleware = ({
  children,
}: Readonly<{ children: React.ReactNode }>) => {
  const { flagsReady } = useFlagsStatus();
  return flagsReady ? children : null;
};

const AppContainer = () => {
  const { isLoggedIn, showNewLandingPage } = useAppContainer();
  useAppRedirection(showNewLandingPage, isLoggedIn);

  return <>{isLoggedIn && showNewLandingPage && <FabAIChatWindow />}</>;
};

export default App;
