import React, { useEffect, useState } from 'react';
import { theme, DataGrid, Modal } from 'ds4-beta';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { getHeader } from './util';
import { getStartCasedString } from '../../../../util';

const StyledTableCell = styled.span`
  ${theme.typography.body1.regular};
  word-break: break-all;
`;

const TableCell = ({ children }: { children: string }) => {
  return <StyledTableCell>{children}</StyledTableCell>;
};

const StyledAnalyticsTable = styled.div`
  width: 100%;
`;

const StyledSummary = styled.p`
  ${theme.typography.body1.regular};
  :first-child {
    margin-top: 0;
  }
`;

const MAX_WIDTH = 480;
const MAX_ROWS_LIMIT = 50;
const MAX_ROWS_LIMIT_EXCEEDS_TEXT =
  'The inquiry yielded over 50 rows of data, with the first 50 displayed here; please specify any filtering or viewing adjustments needed for easier analysis.';
const COLUMNS_TO_SHOW_CARD = 2;
const ROWS_TO_SHOW_CARD = 5;
const ASSISTANT_ROUTE = '/assistant';

export const AnalyticsTable = ({ data, event }) => {
  const { pathname } = useLocation();
  const isSideChatView = pathname !== ASSISTANT_ROUTE;
  const [isExpanded, setIsExpanded] = useState(false);
  const dataset = data?.response?.[0]?.dataset;
  const analysis = data?.response?.[0]?.analysis;
  const cols = dataset?.columns;
  const rows: Array<Record<string, string>> = dataset?.rows ?? [];

  useEffect(() => {
    if (pathname === ASSISTANT_ROUTE) {
      setIsExpanded(false);
    }
  }, [pathname]);

  if (!cols) {
    return null;
  }

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const determineCardOrGrid = (cols, rows) => {
    return (
      cols.length > COLUMNS_TO_SHOW_CARD ||
      (cols.length <= COLUMNS_TO_SHOW_CARD && rows > ROWS_TO_SHOW_CARD)
    );
  };

  const renderDataGrid = ({ sideView, modal }) => {
    const showCard = determineCardOrGrid(cols, rows.length);
    return (
      <DataGrid
        key={sideView ? 'card' : 'datagrid'}
        columns={cols.map(col => ({
          id: col,
          label: getStartCasedString(col),
          ...(col.length > 2 && { maxWidth: MAX_WIDTH }),
          render: (data: string) => <TableCell>{data}</TableCell>,
        }))}
        data={rows.slice(0, MAX_ROWS_LIMIT)}
        isLoading={false}
        headerProps={{
          heading: getHeader(event),
          subTitle:
            rows.length < MAX_ROWS_LIMIT
              ? `Showing ${rows.length} rows`
              : MAX_ROWS_LIMIT_EXCEEDS_TEXT,
        }}
        {...(sideView &&
          !modal &&
          showCard && {
            iconProps: { icon: 'AIExpand', onClick: handleExpandClick },
          })}
        showGrid={!sideView || modal || !showCard}
      />
    );
  };

  return (
    <StyledAnalyticsTable>
      {analysis?.summary?.length > 0 && (
        <StyledSummary>{analysis?.summary}</StyledSummary>
      )}
      {renderDataGrid({ sideView: isSideChatView, modal: false })}
      {isExpanded && isSideChatView && (
        <Modal
          size='large'
          isVisible={isExpanded}
          mode='ai'
          onClose={handleExpandClick}
          dataTestid='analytics-datagrid-modal'
        >
          {renderDataGrid({ sideView: isSideChatView, modal: true })}
        </Modal>
      )}
    </StyledAnalyticsTable>
  );
};
