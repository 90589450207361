import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  HOME_ACTIVITY_ROUTE,
  FAB_AI_ROUTE,
} from '../components/chat-container/constants';

export const useAppRedirection = (newLandingPage, isLoggedIn) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  useEffect(() => {
    if (isLoggedIn && pathname.startsWith(FAB_AI_ROUTE) && !newLandingPage) {
      navigate(`${HOME_ACTIVITY_ROUTE}${search}`, {
        replace: true,
      });
    }
  }, [newLandingPage, isLoggedIn, navigate]);
};
