import { FabAIController } from '@copilot/mfa-communication';
import { AIChatPrompterButton, Icon } from 'ds4-beta';
import React, { useEffect, useState } from 'react';
import { ChipsIconsMapping } from './constants';
import {
  InitialPromptsContainer,
  PredefinedChipContainer,
  StyledHeaderContainer,
  StyledIconContainerRow,
  StyledIconLandingPage,
  StyledSubTitleContainer,
  StyledTitleContainer,
} from './landingPage.style';

const LandingPagePrompts = ({ setSelectedChipQuery, isSideChat }) => {
  const [predefinedChips, setPredefinedChips] = useState([]);

  useEffect(() => {
    const getPredefinedSuggestionChips = async () => {
      const resp = await FabAIController.getPredefinedPrompts();
      setPredefinedChips(resp);
    };
    getPredefinedSuggestionChips();
  }, []);

  const handleChatPrompterClick = predefinedChip => {
    setSelectedChipQuery(predefinedChip?.title);
  };

  return (
    <StyledIconLandingPage>
      <StyledHeaderContainer>
        <StyledIconContainerRow isSideChat={isSideChat}>
          <Icon iconName='Fabric' size={24} />
        </StyledIconContainerRow>
        <StyledTitleContainer isSideChat={isSideChat}>
          Hello. Welcome to fabric!
        </StyledTitleContainer>
        <StyledSubTitleContainer isSideChat={isSideChat}>
          Get started with one of the prompts below or ask me something else.
        </StyledSubTitleContainer>
      </StyledHeaderContainer>
      <InitialPromptsContainer isSideChat={isSideChat} padding={false}>
        {predefinedChips?.map((predefinedChip, index) => {
          return (
            <PredefinedChipContainer
              sm={12}
              md={isSideChat ? 12 : 6}
              lg={isSideChat ? 12 : 6}
              key={`predefined-chip-${predefinedChip.title}`}>
              <AIChatPrompterButton
                dataTestId={`predefined-chip-${index}`}
                iconName={ChipsIconsMapping[predefinedChip.type]}
                title={predefinedChip.title}
                onClick={() => handleChatPrompterClick(predefinedChip)}
              />
            </PredefinedChipContainer>
          );
        })}
      </InitialPromptsContainer>
    </StyledIconLandingPage>
  );
};
export default LandingPagePrompts;
