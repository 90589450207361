import React from 'react';
import Markdown from 'react-markdown';
import { StyledMarkdownContainer } from './MarkdownRenderer.style';

interface MarkdownRendererProps {
  children: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ children }) => {
  return (
    <StyledMarkdownContainer>
      <Markdown>{children}</Markdown>
    </StyledMarkdownContainer>
  );
};

export default MarkdownRenderer;
