export const ChipsIconsMapping = {
    'RECOMMENDATION': 'Product',
    'OMS_FULLFILLMENT_PROMPT': 'Orders',
    'OMS_SHIPMENT_PROMPT': 'Inventory',
    'PIM_DESCRIPTION_PROMPT': 'Product',
    'PIM_CATALOG_PROMPT': 'Product'
}
export const predefinedChips = [
  {
    id: 1,
    query: 'Which fulfillment rules are hurting margins most?',
    icon: 'box',
    type: 'static',
    metadata: {
      displayOrder: 1,
      tooltip: 'This chip highlights fulfillment rules impacting margins.',
    },
  },
  {
    id: 2,
    query: 'Show me locations with the highest short shipment rate this month',
    icon: 'clipboard',
    type: 'interactive',
    metadata: {
      displayOrder: 2,
      tooltip: 'This chip helps identify high short shipment rate locations.',
    },
  },
  {
    id: 3,
    query: 'Show me locations with the highest short shipment rate this month',
    icon: 'clipboard',
    type: 'interactive',
    metadata: {
      displayOrder: 3,
      tooltip: 'This chip helps identify high short shipment rate locations.',
    },
  },
  {
    id: 4,
    query: 'Show me locations with the highest short shipment rate this month',
    icon: 'clipboard',
    type: 'interactive',
    metadata: {
      displayOrder: 3,
      tooltip: 'This chip helps identify high short shipment rate locations.',
    },
  },
];
