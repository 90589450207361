import { useEffect, useState } from 'react';
import { useUnleashContext, useFlag } from '@unleash/proxy-client-react';

const useAppContainer = () => {
  const showNewLandingPage = useFlag('fabai-home-page');
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(sessionStorage.getItem('accessToken')));
  const updateContext = useUnleashContext();

  const updateListener = (e: CustomEvent) => {
    const hasSessionToken = Boolean(sessionStorage.getItem('accessToken'));
    setIsLoggedIn(hasSessionToken);
    updateContext({
      userId: hasSessionToken ? e.detail.accountId : null,
    });
  }

  useEffect(() => {
    window.addEventListener("onUserUpdate", updateListener);

    return () => {
      window.removeEventListener("onUserUpdate", updateListener);
    }
  }, []);

  return {
    showNewLandingPage,
    isLoggedIn
  }
}

export default useAppContainer;