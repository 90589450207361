import { FabAIObservables } from '@copilot/mfa-communication';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FAB_AI_ROUTE } from '../components/chat-container/constants';
import { showShrunkFabAIChat } from '../../util';

const useFabAIChatHomepage = () => {
  const [showDefaultView, setShowDefaultView] = useState(true);
  const [selectedChipQuery, setSelectedChipQuery] = useState('');
  const location = useLocation();

  const handleFabAIExpansion = useCallback(() => {
    if (location.pathname === FAB_AI_ROUTE) {
      FabAIObservables.fullyOpenFabAIChat();
    } else if (showShrunkFabAIChat()) {
      FabAIObservables.shrinkFabAIChat();
    }
  }, [location.pathname]);

  useEffect(() => {
    handleFabAIExpansion();
  }, [location.pathname]);

  return {
    showDefaultView,
    selectedChipQuery,
    setShowDefaultView,
    setSelectedChipQuery,
  };
};
export default useFabAIChatHomepage;
