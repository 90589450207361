import { Nudge } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { QuickReplyType } from '../types';

const QuickReplySection = ({
  quickReplies,
  onQuickReplyClick,
}: {
  quickReplies: QuickReplyType[];
  onQuickReplyClick: (text: string) => void;
}) => {
  if (isEmpty(quickReplies)) {
    return null;
  }
  return (
    <>
      {quickReplies.map(prompt => {
        const key = prompt.attributes?.promptId;
        const text = prompt.attributes?.promptText;
        return (
            <Nudge
              key={key}
              text={text}
              onClick={() => {
                onQuickReplyClick(text);
              }}
            />
        );
      })}
    </>
  );
};

export default QuickReplySection;
