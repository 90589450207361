import { AIChatPrompter, ChatBubble, GenericError, theme } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import ChatBubbleLoadingIcon from '../../../chat-bubble/loading';
import ChatStreaming from './chat-streaming';
import { CHAT_ERROR_MESSAGE, CHAT_MESSAGE_TYPE } from './constants';
import FollowUpSection from './follow-up-section';
import { useChatContainer } from './hook';
import {
  StyledChatContent,
  StyledChatFooter,
  StyledChatInput,
  StyledChatLayout,
  StyledChatRef,
  StyledErrorContainer,
} from './style';

type ChatContainerProps = {
  setShowDefaultView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChipQuery?: string;
  isSideChat?: boolean;
};

const ChatContainer = ({
  setShowDefaultView,
  selectedChipQuery,
  isSideChat,
}: ChatContainerProps) => {
  const {
    messages,
    label,
    loading,
    setLoading,
    chatContainerRef,
    input,
    handleSubmit,
    handleInputSubmit,
    setInput,
    quickReplies,
    uiActionCTAs,
  } = useChatContainer({ selectedChipQuery, setShowDefaultView });

  return (
    <StyledChatLayout isSideChat={isSideChat} hasMessages={!isEmpty(messages)}>
      <StyledChatContent>
        {!isEmpty(messages) &&
          messages.map((message, idx) => {
            if (message.type === CHAT_MESSAGE_TYPE.USER) {
              return (
                <div
                  key={message.id}
                  style={{ padding: `${theme.space[6]} 0px` }}
                >
                  <ChatBubble
                    content={message.content}
                    sender={CHAT_MESSAGE_TYPE.USER}
                    avatar={{ label }}
                  />
                </div>
              );
            } else if (message.type === CHAT_MESSAGE_TYPE.AI) {
              return (
                <ChatStreaming
                  isSideChat={isSideChat}
                  key={message.id}
                  content={message.content}
                  setLoading={setLoading}
                  loading={loading && idx === messages.length - 1}
                />
              );
            } else if (message.type === CHAT_MESSAGE_TYPE.ERROR) {
              return (
                <StyledErrorContainer key={message.id}>
                  <GenericError
                    error={CHAT_ERROR_MESSAGE}
                    dataTestid='chat-error'
                  />
                </StyledErrorContainer>
              );
            }
          })}
        {loading && <ChatBubbleLoadingIcon />}
        {(!isEmpty(quickReplies) || !isEmpty(uiActionCTAs)) &&
          <FollowUpSection
            quickReplies={quickReplies}
            uiActionCTAs={uiActionCTAs}
            handleFollowUpClick={(text: string, shouldTriggerResponse: boolean) => {
              handleInputSubmit(text, shouldTriggerResponse);
            }} />
        }
        <StyledChatRef data-testid='chat-scroll' ref={chatContainerRef} />
      </StyledChatContent>
      <StyledChatFooter>
        <StyledChatInput isSideChat={isSideChat}>
          <AIChatPrompter
            placeholder='Ask me anything.'
            onChange={e => {
              setInput(e.target.value);
            }}
            onSubmit={handleSubmit}
            promptValue={input || ''}
            subTitle='Everyone makes mistakes, even AI.  Always triple check your work and verify generated responses.'
          />
        </StyledChatInput>
      </StyledChatFooter>
    </StyledChatLayout>
  );
};

export default ChatContainer;
