import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'ds4-beta';
import App from './app';

export default function Root() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  );
}