import { AIChatResponse, Box, theme } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { getStartCasedString } from '../../../../util';
import {
  StyledBoldText,
  StyledHeading,
  StyledProductContainer,
} from '../style';

export const ProductDescription = ({ data, ...restProps }) => {
  return (
    <StyledProductContainer {...restProps}>
      {data?.response?.map(res => {
        const productDescriptionObj = res?.element?.attributes.find(
          obj => obj.name === 'Description'
        );

        return (
          <>
            {data?.response[0]?.element && data?.response[0]?.analysis && (
              <StyledHeading>
                I’ve analyzed the description of Product{' '}
                <StyledBoldText>{res?.element?.identifier}. </StyledBoldText>
              </StyledHeading>
            )}

            {!isEmpty(productDescriptionObj) && (
              <div className='chat-response-wrapper'>
                <AIChatResponse
                  title={productDescriptionObj?.name}
                  pill={productDescriptionObj.score}
                  pillType={(productDescriptionObj.score || '').toUpperCase()}
                  description={[productDescriptionObj.value]}
                />
              </div>
            )}
            {!isEmpty(res.analysis) && (
              <>
                <div
                  className='chat-response-wrapper'
                  style={{ marginBottom: theme.space[4] }}
                >
                  <AIChatResponse title='Description Analysis' />
                </div>
                {res.analysis.parameters.map(param => {
                  const _title = getStartCasedString(param.attribute);
                  const strengths = !isEmpty(param.reasons) ? `Strengths: ${param.reasons.join(' ')}` : null;
                  const opportunities = !isEmpty(param.suggestions) ? `Opportunities: ${param.suggestions.join(' ')}` : null;
                  return (
                    <Box key={param.attribute}>
                      <div className='chat-response-wrapper'>
                        <AIChatResponse
                          title={_title}
                          pill={param.value === 'bad' ? 'POOR' : param.value}
                          pillType={param.value.toUpperCase()}
                          description={[strengths, opportunities].filter(e => e)}
                        />
                      </div>
                    </Box>
                  );
                })}
              </>
            )}
            {!isEmpty(res?.recommendations[0]?.analysis) && (
              <>
                <div
                  className='chat-response-wrapper'
                  style={{ marginBottom: theme.space[4] }}
                >
                  <AIChatResponse title='Recommendations' />
                </div>
                {res?.recommendations[0]?.value && (
                  <div className='chat-response-wrapper'>
                    <AIChatResponse
                      title='Optimized description'
                      description={[res?.recommendations[0]?.value]}
                    />
                  </div>
                )}
                {res.recommendations[0].analysis.parameters.map(rec => {
                  const _title = getStartCasedString(rec.attribute);
                  return (
                    <div key={rec.attribute} className='chat-response-wrapper'>
                      <AIChatResponse
                        title={_title}
                        description={rec.reasons}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
      })}
    </StyledProductContainer>
  );
};
