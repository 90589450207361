import { SUPPORTED_EVENTS } from '../constants';

export const getHeader = (event: string) => {
  const { PIM_ANALYTICS, OMS_ANALYTICS } = SUPPORTED_EVENTS;
  switch (event) {
    case OMS_ANALYTICS:
      return 'Orders';
    case PIM_ANALYTICS:
      return 'Products';
    default:
      return '';
  }
};

export const DOMAIN_APP_DEFAULT_ROUTEMAP = {
  product: '/products/items',
  orders: '/orders',
  inventory: '/inventory/manage-inventory',
};
