import { FabAIController, FabAIObservables } from '@copilot/mfa-communication';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { gracefulJSONParse } from '../../../util';
import {
  CHAT_MESSAGE_TYPE,
  CHAT_STREAMING_PAYLOAD,
  FABAI_CONVERSATION_ID,
  SUPPORTED_EVENT_TYPES,
  SUPPORTED_EVENTS,
} from './constants';
import { ACTION_TYPE_OPTIONS, QuickReplyType, UIActionType } from './follow-up-section/types';
import JsonRenderer from './json-renderer';
import { MarkdownRenderer } from './MarkdownRenderer';
import { updateMessages } from './util';

const sanitizeMarkdown = (text: string): string => {
  return text.replace(/\[NEWLINE\]+/g, `\n`);
};

const isValidStreamData = (streamData: string) => {
  const lines = streamData.split('\n\n');
  let isValid = true;
  lines.forEach(line => {
    if (line.trim()) {
      try {
        const jsonString = line.replace(/^data:\s*/, '');
        JSON.parse(jsonString);
      } catch (e) {
        isValid = false;
      }
    }
  });
  return isValid;
};

export const useChatStreaming = ({ content, setLoading }) => {
  const [messages, setMessages] = useState([]);

  const isLastAiMessage = useMemo(() => {
    return messages[messages.length - 1]?.type === CHAT_MESSAGE_TYPE.AI
  }, [messages]);

  const renderMessages = useMemo(() => {
    const renderedMessages = [];
    let currentText = '';

    messages.forEach((msg, index) => {
      if (msg.eventType === SUPPORTED_EVENT_TYPES.TEXT) {
        currentText += msg.data;
      }

      if (
        msg.eventType === SUPPORTED_EVENT_TYPES.JSON ||
        index === messages.length - 1
      ) {
        if (currentText) {
          renderedMessages.push(
            <MarkdownRenderer>{sanitizeMarkdown(currentText)}</MarkdownRenderer>
          );
          currentText = '';
        }

        if (msg.eventType === SUPPORTED_EVENT_TYPES.JSON) {
          renderedMessages.push(
            <JsonRenderer
              key={msg.id}
              content={msg}
              className='fade-in'
              data-testid={`json-response-${index}`}
            />
          );
        }
      }
    });
    return renderedMessages;
  }, [messages]);

  useEffect(() => {
    setMessages(content);
  }, [content]);

  useEffect(() => {
    if (messages.length > 0) {
      setLoading(false);
    }
  }, [messages]);

  return { isLastAiMessage, renderMessages };
};

export const useChatContainer = ({ setShowDefaultView, selectedChipQuery }) => {
  const [messages, setMessages] = useState([]);
  const [quickReplies, setQuickReplies] = useState<QuickReplyType[]>([]);
  const [uiActionCTAs, setUIActionCTAs] = useState<UIActionType[]>([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const label = useMemo(
    () =>
      ['firstName', 'lastName']
        .map(key => sessionStorage.getItem(key) || '')
        .join(' '),
    []
  );

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;
    chatContainer.scrollIntoView({
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    if (messages.length > 0) {
      setShowDefaultView(false);
    }
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const subscription = FabAIObservables.chatExpansionState$.subscribe(() => {
      // Ensure that whenever the chatExpansion state changes, the user is scrolled to the bottom
      scrollToBottom();
    });

    return () => subscription?.unsubscribe();
  }, []);


  useEffect(() => {
    if (selectedChipQuery?.length > 0) {
      setInput(selectedChipQuery);
      handleInputSubmit(selectedChipQuery);
      setInput('');
    }
  }, [selectedChipQuery]);

  const fetchStream = async (promptText: string, chatStreamingPayload) => {
    setLoading(true);
    try {
      const messageId = uniqueId();
      const stream = await FabAIController.getChatStream({
        prompt: promptText,
        userId: sessionStorage.getItem('userId'),
        ...chatStreamingPayload,
      });
      
      const reader = stream.getReader();

      let events = [];

      let streamData = '';

      while (true) {
        if (loading) {
          setLoading(false);
        }
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        streamData += chunk;
        if (!isValidStreamData(streamData)) {
          continue;
        }

        const lines = streamData.split('\n\n');

        lines.forEach(line => {
          if (line.trim()) {
            try {
              const jsonString = line.replace(/^data:\s*/, '');
              const event = JSON.parse(jsonString);

              if (event.event === SUPPORTED_EVENTS.CTA_SUGGESTIONS) {
                const ctaList = gracefulJSONParse(event.data);
                const ctaGroupedByActionType = groupBy(ctaList, 'actionType');
                setQuickReplies(ctaGroupedByActionType[ACTION_TYPE_OPTIONS.QUICK_REPLY]);
                setUIActionCTAs(ctaGroupedByActionType[ACTION_TYPE_OPTIONS.UI_ACTION]);
              } else {
                events.push(event);
              }
            } catch (e) {
              console.error('Error parsing event data:', e);
            }
          }
        });

        streamData = '';

        updateMessagesState(events, messageId);
      }

      handleStreamCompletion();
    } catch (err) {
      setLoading(false);
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.ERROR, content: err.message },
      ]);
    }
  };

  const updateMessagesState = (events, messageId) => {
    // Sort and set messages in state
    events = events.map((ev, index) => ({
      ...ev,
      showFabAiIcon: index === 0,
    }));

    events.sort((a, b) => a.id - b.id);
    setMessages(prev => updateMessages(prev, messageId, events));
  };

  const handleStreamCompletion = () => {
    setLoading(false);
  };

  const handleSubmit = async () => {
    handleInputSubmit(input);
    setInput('');
  };

  const handleInputSubmit = async (inputValue: string, shouldTriggerResponse: boolean = true) => {
    if (!isEmpty(quickReplies)) {
      setQuickReplies([]);
    }
    if (!isEmpty(uiActionCTAs)) {
      setUIActionCTAs([]);
    }
    if (inputValue.length > 0 && !loading) {
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.USER, content: inputValue },
      ]);
      if (shouldTriggerResponse) {
        window.sessionStorage.setItem(FABAI_CONVERSATION_ID, CHAT_STREAMING_PAYLOAD.conversationId)
        // setConversationId(CHAT_STREAMING_PAYLOAD.conversationId);
        await fetchStream(inputValue, CHAT_STREAMING_PAYLOAD);

      }
    }
  };

  return {
    messages,
    label,
    loading,
    setLoading,
    chatContainerRef,
    input,
    handleSubmit,
    setInput,
    handleInputSubmit,
    quickReplies,
    uiActionCTAs,
  };
};
