import { theme } from 'ds4-beta';
import styled from 'styled-components';
import { AI_ICON_SIZE } from '../constants';

export const FollowUpSectionContainer = styled.div`
  display: flex;
  row-gap: ${theme.space[2]};
  margin-top: ${theme.space[4]};
  column-gap: ${theme.space[2]};
  flex-wrap: wrap;
  // theme.space[4] and theme.space[2] are from the "margin-left" and "gap" attributes in StyledChatStreamContainer
  padding-left: calc(
    ${AI_ICON_SIZE}px + ${theme.space[4]} + ${theme.space[2]} - 2px
  ); // calculates to 42px currently
`;
