import { CHAT_MESSAGE_TYPE, FABAI_CONVERSATION_ID } from './constants';
import { FabAIObservables, FabAIController } from '@copilot/mfa-communication';

const updateMessage = (message, messageId, events) => {
  if (message.id === messageId) {
    return {
      id: messageId,
      type: CHAT_MESSAGE_TYPE.AI,
      content: events,
    };
  }
  return message;
};

const createNewMessage = (messageId, events) => ({
  id: messageId,
  type: CHAT_MESSAGE_TYPE.AI,
  content: events,
});

export const updateMessages = (prevMessages, messageId, events) => {
  const updatedMessages = prevMessages.map(message =>
    updateMessage(message, messageId, events)
  );

  const messageExists = prevMessages.find(msg => msg.id === messageId);

  return messageExists
    ? updatedMessages
    : updatedMessages.concat(createNewMessage(messageId, events));
};

export const getConversationId = () => {
  return window.sessionStorage.getItem(FABAI_CONVERSATION_ID);
};

export const makeAcknowledgementAPICall = async dataFromChat => {
  try {
    const userId = window.sessionStorage.getItem('userId');
    const conversationId = getConversationId();
    if (
      FabAIObservables.RxJsService.validateDataToChatbot(dataFromChat) &&
      conversationId &&
      userId
    ) {
      await FabAIController.sendUserAcknowledgement({
        ...dataFromChat.data,
        conversationId,
        userId,
      });
    }
  } catch (e) {
    console.log('error in acknowledgement API call');
    console.log(e);
  }
};
