import React from 'react';
import QuickReplySection from './quick-reply/quick-reply-section';
import { FollowUpSectionContainer } from './style';
import { FollowUpSectionProps } from './types';
import UIActionSection from './ui-action';

const FollowUpSection = ({
  quickReplies,
  uiActionCTAs,
  handleFollowUpClick,
  isSideChat,
}: FollowUpSectionProps) => {
  return (
    <FollowUpSectionContainer isSideChat={isSideChat}>
      <QuickReplySection
        quickReplies={quickReplies}
        onQuickReplyClick={handleFollowUpClick}
      />
      <UIActionSection
        uiActionCTAs={uiActionCTAs}
        onUIActionClick={handleFollowUpClick}
      />
    </FollowUpSectionContainer>
  );
};

export default FollowUpSection;
