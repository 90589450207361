import { FabAIObservables } from '@copilot/mfa-communication';
import React, { useEffect, useState } from 'react';
import ChatContainer from './components/chat-container/chat-container';
import { makeAcknowledgementAPICall } from './components/chat-container/util';
import {
  ChatPageContainer,
  StyledLandingPageContainer,
} from './components/home-page/landingPage.style';
import LandingPagePrompts from './components/home-page/landingPagePrompts';
import useFabAIChatHomepage from './hooks/useFabAIChatHomepage';

const FabAIChatHomePage = () => {
  const {
    showDefaultView,
    setShowDefaultView,
    selectedChipQuery,
    setSelectedChipQuery,
  } = useFabAIChatHomepage();

  const [isSideChat, setIsSideChat] = useState<boolean>(null);

  useEffect(() => {
    FabAIObservables.RxJsService.receiveDataToChatbot().subscribe({
      next: async dataFromChat => makeAcknowledgementAPICall(dataFromChat),
    });
  }, []);

  useEffect(() => {
    const subscription = FabAIObservables.chatExpansionState$.subscribe((expansionState) => {
      setIsSideChat(expansionState === FabAIObservables.ChatExpansionStateType.THIRD);
    });

    return () => subscription?.unsubscribe();
  }, [location]);

  return (
    <ChatPageContainer isSideChat={isSideChat}>
      {showDefaultView && (
        <StyledLandingPageContainer isSideChat={isSideChat}>
          <LandingPagePrompts isSideChat={isSideChat} setSelectedChipQuery={setSelectedChipQuery} />
        </StyledLandingPageContainer>
      )}
      <ChatContainer
        isSideChat={isSideChat}
        setShowDefaultView={setShowDefaultView}
        selectedChipQuery={selectedChipQuery}
      />
    </ChatPageContainer>
  );
};
export default FabAIChatHomePage;
